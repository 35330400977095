<template>
    <header class="d-flex flex-column">
        <div class="header text-center">
            <Logo
                small
                light
            />
        </div>
        <div class="etapas">
            <v-stepper
                v-model="activeStep"
                class="elevation-0 transparent"
            >
                <v-stepper-header>
                    <v-stepper-step
                        step="1"
                        :complete="activeStep > 1"
                    >
                        Serviço desejado
                    </v-stepper-step>
                    <v-divider />
                    <v-stepper-step
                        step="2"
                        :complete="activeStep > 2"
                    >
                        Simulacao Preço
                    </v-stepper-step>
                    <v-divider />
                    <v-stepper-step
                        step="3"
                        :complete="activeStep > 3"
                    >
                        Dados Pessoais
                    </v-stepper-step>
                    <v-divider />
                    <v-stepper-step
                        step="4"
                        :complete="activeStep > 4"
                    >
                        Confirmação Email
                    </v-stepper-step>
                    <v-divider />
                    <v-stepper-step
                        step="5"
                        :complete="activeStep > 5"
                    >
                        Dados Empresa
                    </v-stepper-step>
                    <v-divider />
                    <v-stepper-step
                        step="6"
                        :complete="activeStep > 5"
                    >
                        Análise
                    </v-stepper-step>
                </v-stepper-header>
            </v-stepper>
        </div>
    </header>
</template>

<script>
import Logo from "./Logo";
export default {
    name: "EtapasHeader",
    components: {
        Logo,
    },

    computed: {
        activeStep: {
            get() {
                return this.$store.getters["activeStep"];
            },
        },
    },
};
</script>

<style lang="scss" scoped>
header {
  max-height: 170px;
  padding: 10px 10px 20px 10px !important;
  border-bottom: 2px solid $theme-pink;
}
</style>
<style lang="scss">
.v-stepper {
  width: 100%;
}
</style>

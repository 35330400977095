import Vue from 'vue'
import Vuex from 'vuex'
import { authAPI } from '../services/services'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        resendingToken: false,
        tokenConfirmed: false,
        activeStep: 1,
        tokenSent: false,
        tokenTimer: 0,
        token: '',
        snackbarStatus: false,
        snackbarMessage: '',
        snackbarTimeout: 5000
    },
    mutations: {
        resendingToken(state, v) {
            state.resendingToken = v
        },
        tokenConfirmed(state, v) {
            state.tokenConfirmed = v
        },
        nextStep(state) {
            if (state.invalidData) return
            state.activeStep += 1
        },
        returnStep(state) {
            state.activeStep > 1 ? (state.activeStep -= 1) : null
        },
        setStep(state, step) {
            state.activeStep = step
        },
        tokenSent(state, status) {
            if (state.tokenSent) return
            state.tokenSent = status
        },
        token(state, v) {
            state.token = v
        },
        startTokenTimer(state, duration = 60 * 5) {
            if (state.tokenTimer != 0) return

            state.tokenTimer = duration
            let timer = setInterval(() => {
                state.tokenTimer -= 1
                if (state.tokenTimer === 0) stopTimer()
            }, 1000)

            const stopTimer = () => {
                state.tokenSent = false
                clearInterval(timer)
            }
        },
        updateSnackbarMessage(state, { message, timeout }) {
            if (state.snackbarStatus) return

            setTimeout(() => {
                state.snackbarStatus = false
            }, timeout)
            state.snackbarStatus = true
            state.snackbarMessage = message
            state.snackbarTimeout = timeout
        },
        snackbar(state, value) {
            state.snackbarStatus = value
        }
    },
    getters: {
        tokenConfirmed(state) {
            return state.tokenConfirmed
        },
        token(state) {
            return state.token
        },
        resendingToken(state) {
            return state.resendingToken
        },
        activeStep(state) {
            return state.activeStep
        },
        tokenSent(state) {
            return state.tokenSent
        },
        parsedTokenTimer(state) {
            const minutes = Math.floor(state.tokenTimer / 60)
            let seconds = state.tokenTimer % 60
            seconds = seconds < 10 ? `0${seconds}` : seconds
            return `${minutes}:${seconds}`
        },
        snackbarMessage(state) {
            return state.snackbarMessage
        },
        snackbarStatus(state) {
            return state.snackbarStatus
        },
        snackbarTimeout(state) {
            return state.snackbarTimeout
        }
    },
    actions: {
        async sendRegistrationForm({ state }) {
            const json = {
                empresa: {
                    ...state.empresa
                },
                usuario: {
                    ...state.usuario,
                    token: state.token
                }
            }
            delete json.usuario.confirmarEmail
            delete json.usuario.confirmarSenha
            return authAPI.sendRegistratonData(json)
        }
    },
    modules: {
        usuario: {
            namespaced: true,
            state: {
                tipoServico: 'contabilidade',
                nome: '',
                sobrenome: '',
                email: '',
                confirmarEmail: '',
                cpf: '',
                telefone: '',
                senha: '',
                confirmarSenha: ''
            },
            mutations: {
                tipoServico(state, message) {
                    state.tipoServico = message
                },
                nome(state, message) {
                    state.nome = message
                },
                sobrenome(state, message) {
                    state.sobrenome = message
                },
                email(state, message) {
                    state.email = message
                },
                confirmarEmail(state, message) {
                    state.confirmarEmail = message
                },
                cpf(state, message) {
                    state.cpf = message
                },
                telefone(state, message) {
                    state.telefone = message
                },
                senha(state, value) {
                    state.senha = value
                },
                confirmarSenha(state, value) {
                    state.confirmarSenha = value
                }
            },
            getters: {
                tipoServico(state) {
                    return state.tipoServico
                },
                nome(state) {
                    return state.nome
                },
                sobrenome(state) {
                    return state.sobrenome
                },
                email(state) {
                    return state.email
                },
                confirmarEmail(state) {
                    return state.confirmarEmail
                },
                cpf(state) {
                    return state.cpf
                },
                telefone(state) {
                    return state.telefone
                },
                senha(state) {
                    return state.senha
                },
                confirmarSenha(state) {
                    return state.confirmarSenha
                }
            }
        },
        empresa: {
            namespaced: true,
            state: {
                cnpj: '',
                cep: '',
                numero: '',
                logradouro: '',
                bairro: '',
                cidade: '',
                uf: '',
                complemento: '',
                regime: '',
                atividade: ''
            },
            mutations: {
                cnpj(state, value) {
                    state.cnpj = value
                },
                cep(state, value) {
                    state.cep = value
                },
                numero(state, value) {
                    state.numero = value
                },
                logradouro(state, value) {
                    state.logradouro = value
                },
                complemento(state, value) {
                    state.complemento = value
                },
                bairro(state, value) {
                    state.bairro = value
                },
                cidade(state, value) {
                    state.cidade = value
                },
                uf(state, value) {
                    state.uf = value
                },
                regime(state, value) {
                    state.regime = value
                    state.atividade = ''
                },
                atividade(state, value) {
                    if (state.regime == 'mei'){
                        state.atividade = value
                    } else {
                        state.atividade = 'servicos'
                    }
                }
            },
            getters: {
                cnpj(state) {
                    return state.cnpj
                },
                cep(state) {
                    return state.cep
                },
                numero(state) {
                    return state.numero
                },
                logradouro(state) {
                    return state.logradouro
                },
                complemento(state) {
                    return state.complemento
                },
                bairro(state) {
                    return state.bairro
                },
                cidade(state) {
                    return state.cidade
                },
                uf(state) {
                    return state.uf
                },
                regime(state) {
                    return state.regime
                },
                atividade(state) {
                    return state.atividade
                }
            }
        }
    }
})

import Vue from 'vue'
import VueRouter from 'vue-router'
import Cadastro from '../views/Cadastro.vue'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'Crie sua conta — NUCO'

const routes = [
    {
        path: '/',
        name: 'Cadastro',
        component: Cadastro
    },
    {
        path: '*',
        redirect: '/',
        component: Cadastro
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.afterEach(to => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE
    })
})

export default router

import axios from 'axios'

// axios.interceptors.request.use(r => {
//     console.log(r)
//     return r
// })
// axios.interceptors.response.use(r => {
//     console.log(r)
//     return r
// })

const nucoInstance = axios.create({
    baseURL: process.env.VUE_APP_NUCO_BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    },
})
export const authAPI = {
    async generateRegistrationToken(payload) {
        return nucoInstance.post('/auth/generate-token', payload)
    },
    async sendRegistrationToken(payload) {
        return nucoInstance.post('/auth/confirm-token', payload)
    },
    async sendRegistratonData(payload) {
        return nucoInstance.post('/auth/register', payload)
    },
}
export const checkAPI = {
    async checkCPF(cpf) {
        return nucoInstance.get(`/api/check-cpf/${cpf}`)
    },
    async checkCNPJ(cnpj) {
        return nucoInstance.get(`/api/check-cnpj/${cnpj}`)
    },
    async checkEmail(email) {
        return nucoInstance.get(`/api/check-email/${email}`)
    }
}

export async function fetchCep(cep) {
    return axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(response => response.data)
}

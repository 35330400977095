<template>
    <a
        :href="nucoLanding"
        class="logo"
        :class="{ light: light, dark: dark, big: big, small: small }"
    >
        <img
            v-if="!noImg"
            src="@/assets/svg/logo.svg"
            alt="nuco"
        >
        <span
            v-if="!noText"
            class="logo-text"
        >NUCO</span>
    </a>
</template>

<script>
export default {
    name: 'Logo',
    props: {
        light: Boolean,
        dark: Boolean,
        big: Boolean,
        small: Boolean,
        noImg: Boolean,
        noText: Boolean
    },
    data(){
        return {
            nucoLanding: process.env.VUE_APP_NUCO_LANDING
        }
    }
}
</script>

<style lang="scss" scoped>
a {
    text-decoration: none !important;
}
.logo {
    padding: 10px 0;
    display: inline-block;
    white-space: nowrap;
    &.light {
        span {
            color: lighten($theme-text-black, 5%);
        }
    }
    &.small {
        img {
            width: 31px;
        }
        span {
            font-size: 2.8rem;
        }
        padding: 0;
    }
    img {
        width: 40px;
        display: inline-block;
    }
    span {
        margin-left: 5px;
        font-size: 3.5rem;
        font-weight: bold;
        color: white;
    }
}
</style>

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primaryVidid: '#ed1c94',
        primary: '#e0398a',
        secondary: '#981df0',
        success: '#6cc070'
      }
    }
  }
}
export default new Vuetify(opts)

import Vue from 'vue'
import App from './App.vue'

import vuetify from './plugins/vuetify'

import router from './router/index'
import store from './store/store'

import 'font-awesome/css/font-awesome.min.css'
import './plugins/axios'
import Vuelidadate from 'vuelidate'

Vue.use(Vuelidadate)
Vue.config.productionTip = false
new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')

<template>
	<aside class="steps align-center justify-center">
		<div class="content align-center d-flex flex-column">
			<div class="header text-center">
				<Logo light />
			</div>
			<div
				class="etapas d-flex flex-column justify-center align-center flex-grow"
			>
				<span class="text-h4 font-weight-light mb-2">Etapas</span>
				<div class="divider" />
				<v-stepper
					v-model="activeStep"
					vertical
					class="elevation-0"
				>
					<v-stepper-step
						step="1"
						:complete="activeStep > 1"
						class="step-1"
					>
						Serviço desejado
					</v-stepper-step>
					<v-stepper-content step="1" />
					<v-stepper-step
						step="2"
						:complete="activeStep > 2"
						class="step-2"
					>
						{{ titulos[tipoServico] }}
					</v-stepper-step>
					<v-stepper-content step="2" />
					<v-stepper-step
						step="3"
						:complete="activeStep > 3"
						class="step-3"
					>
						Dados Pessoais
					</v-stepper-step>
					<v-stepper-content step="3" />
					<v-stepper-step
						step="4"
						:complete="activeStep > 4"
						class="step-4"
					>
						Confirmação Email
					</v-stepper-step>
					<v-stepper-content step="4" />
					<v-stepper-step
						step="5"
						:complete="activeStep > 5"
						class="step-5"
					>
						Dados Empresa
					</v-stepper-step>
					<v-stepper-content step="5" />
					<v-stepper-step
						step="6"
						:complete="activeStep > 6"
						class="step-6"
					>
						Análise
					</v-stepper-step>
					<v-stepper-content step="6" />
				</v-stepper>
			</div>
			<div class="footer d-flex flex-column align-center">
				<img
					class="d-none d-md-block"
					src="../assets/svg/cadastro.svg"
					alt=""
				>
			</div>
		</div>
	</aside>
</template>

<script>
import Logo from "./Logo";
export default {
    name: "EtapasSide",
    components: { Logo },
    props: ["tipoServico", "steps"],
    data() {
        return {
            titulos: {
                "abertura-contabilidade": "Restrições",
                contabilidade: "Simulação Preço",
                abertura: "Preço",
            },
        };
    },
    computed: {
        activeStep: {
            get() {
                return this.$store.getters["activeStep"];
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.steps {
  top: 0;
  position: sticky;
  height: 100vh;
  min-width: 300px;
  &::after {
    content: "";
    position: absolute;
    top: 7.5%;
    left: 0;
    width: 1px;
    height: 85%;
    background: $theme-pink;
  }
  .content {
    justify-self: center;
    align-self: center;
    min-height: 90%;
  }
}
.footer {
  img {
    width: 70%;
    align-self: center;
  }
}
</style>

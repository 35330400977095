<template>
	<div
		id="cadastro-wrap"
		class=""
	>
		<EtapasHeader class="d-block d-md-none" />
		<div class="main d-flex flex-column align-center justify-center">
			<transition
				name="left-join"
				mode="out-in"
			>
				<TipoServico v-if="activeStep === 1" />
				<PrecoContabilidade v-if="activeStep === 2" />
				<UsuarioForm v-if="activeStep === 3" />
				<ConfirmacaoEmail v-if="activeStep === 4" />
				<EmpresaForm v-if="activeStep === 5" />
				<Analise v-if="activeStep === 6" />
			</transition>
			<v-snackbar
				v-model="snackbarStatus"
				:timeout="snackbarTimeout"
				centered
				color="error"
				rounded="pill"
			>
				{{ snackbarMessage }}
				<template v-slot:action="{ attrs }">
					<v-btn
						color="white"
						v-bind="attrs"
						icon
						@click="snackbarStatus = false"
					>
						<v-icon color="white">
							mdi-close
						</v-icon>
					</v-btn>
				</template>
			</v-snackbar>
		</div>
		<EtapasSide
			class="d-none d-md-flex"
			:tipo-servico="tipoServico"
		/>
	</div>
</template>

<script>

import Loading from "../components/Loading.vue";
import EtapasSide from "@/components/EtapasSide.vue";
import EtapasHeader from "@/components/EtapasHeader.vue";

export default {
    name: "Cadastro",
    components: {
        EtapasSide,
        EtapasHeader,
        TipoServico: () => ({
            component: import(
        /* webpackPrefetch: true */ "@/components/TipoServico.vue"
      ),
            loading: Loading,
        }),
        PrecoContabilidade: () => ({
            component: import(
        /* webpackPrefetch: true */ "@/components/PrecoContabilidade.vue"
      ),
            loading: Loading,
        }),
        ConfirmacaoEmail: () => ({
            component: import(
        /* webpackPrefetch: true */ "@/components/ConfirmacaoEmail.vue"
      ),
            loading: Loading,
        }),
        UsuarioForm: () => ({
            component: import(
        /* webpackPrefetch: true */ "@/components/UsuarioForm.vue"
      ),
            loading: Loading,
        }),
        EmpresaForm: () => ({
            component: import(
        /* webpackPrefetch: true */ "@/components/EmpresaForm.vue"
      ),
            loading: Loading,
        }),
        Analise: () => ({
            component: import(/* webpackPrefetch: true */ "@/components/Analise.vue"),
            loading: Loading,
        }),
    },
    computed: {
        tipoServico: {
            get() {
                return this.$store.getters["usuario/tipoServico"];
            },
        },
        activeStep: {
            get() {
                return this.$store.getters["activeStep"];
            },
        },
        snackbarStatus: {
            get() {
                return this.$store.getters["snackbarStatus"];
            },
            set(value) {
                this.$store.commit("snackbar", value);
            },
        },
        snackbarMessage: {
            get() {
                return this.$store.getters["snackbarMessage"];
            },
        },
        snackbarTimeout: {
            get() {
                return this.$store.getters["snackbarTimeout"];
            },
        },
    },
    mounted() {
        if (process.env.VUE_APP_NUCO_BASE_URL === 'https://homolog.nuco.com.br') console.log('homolog');
    },
};
</script>

<style lang="scss" scoped>
#cadastro-wrap {
  background: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
#wrap {
  min-height: 100vh;
  background: white;
}
.main {
  flex: 1;
  padding: 1rem 2rem;
  max-width: 700px;
  margin: 0 auto;
}
header {
  padding: 20px 50px;
  width: 100%;
  background: white;
}

@media screen and (min-width: 600px) {
  .main {
    padding: 2rem 5rem;
  }
}
@media screen and (min-width: 960px) {
  #cadastro-wrap {
    background: white;
    display: grid;
    grid-template-columns: 1.2fr 0.8fr;
    margin: 0 auto;
  }
  .main {
    padding: 5rem 5rem;
  }
}
@media screen and (min-width: 1300px) {
  #cadastro-wrap {
    max-width: 1300px;
    margin: 0 auto;
  }
  .main {
    padding: 5rem;
  }
}
</style>

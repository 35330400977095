<template>
	<v-app id="app">
		<v-main class="flex-column">
			<transition mode="out-in">
				<router-view />
			</transition>
		</v-main>
	</v-app>
</template>

<script>
export default {
    name: 'App'
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900%7CRoboto+Mono:500%7CMaterial+Icons');

.text-center {
	text-align: center !important;
}
.flex-row {
    flex-direction: row !important;
}
.divider {
    background: linear-gradient(to right, $theme-pink 50%, $theme-purple 120%);
    display: inline-block;
    border: 0;
    font-size: 0;
    line-height: 0;
    height: 3px;
    max-width: 100px;
    width: 100%;
}
#app {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    position: relative;
    background: white;
}

* {
    transition: all 0.3s;
    box-sizing: border-box;
}
body,
ul,
li,
h1,
h2,
h3,
h4 p {
    padding: 0;
    margin: 0;
    font-weight: 300;
}

label {
    margin-bottom: 5px;
}
ul {
    list-style: none;
}
body {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.flex-grow {
    flex: 1;
}
@media screen and (max-width: 360px) {
    section {
        padding: 40px;
    }
}
section {
    font-size: 1rem;
    font-weight: 300;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 50px;
    width: 100%;
}

h2 {
    font-weight: 300;
    font-size: 3.5rem;
    color: $theme-text-black;
}
h1 {
    font-weight: normal;
    font-size: 4rem;
    color: $theme-text-black;
}
h3 {
    margin: 0;
    font-weight: 300;
    font-size: 3rem;
    color: $theme-text-black;
}
.v-enter {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
}
.v-leave-to {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
}
.v-enter-active,
.v-leave-active {
    transition: all 0.3s;
}
.left-join-enter {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
}
.left-join-leave-to {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
}
.left-join-enter-active,
.left-join-leave-active {
    transition: all 0.3s;
}
</style>
